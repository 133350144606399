import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import {
    mdiEye, mdiEyeOff, mdiCheck, mdiCheckDecagram, mdiCheckDecagramOutline, mdiCancel, mdiClose, mdiCheckboxBlankOutline, mdiCheckboxMarked, mdiCloseCircle, mdiMenuDown,
    mdiArrowLeft, mdiArrowRight, mdiSend, mdiCheckboxMarkedCircleOutline, mdiMagnify, mdiViewDashboardOutline, mdiAccountEditOutline, mdiLogout, mdiAccount, mdiInformationOutline,
    mdiTextBoxSearch, mdiTextBoxSearchOutline, mdiExitToApp, mdiClipboardOutline, mdiHelpCircle, mdiDatabaseCogOutline, mdiCheckAll, mdiDownload,
    mdiExport, mdiImport, mdiDatabaseRemoveOutline, mdiDatabasePlusOutline, mdiStar, mdiStarOutline
} from '@mdi/js'

//Usage '$vuetify.icons.xbox'
const MY_ICONS = {

    /*Default List*/
    eye: mdiEye,
    eyeoff: mdiEyeOff,
    complete: mdiCheck,
    cancel: mdiCancel,
    close: mdiClose,
    delete: mdiCloseCircle, // delete (e.g. v-chip close)
    clear: mdiClose,
    success: mdiCheck,
    successOutline: mdiCheckDecagramOutline,
    successBold: mdiCheckDecagram,
    info: mdiInformationOutline,
    warning: '...',
    error: '...',
    prev: '...',
    next: '...',
    checkboxOn: mdiCheckboxMarked,
    checkboxOff: mdiCheckboxBlankOutline,
    checkboxIndeterminate: '...',
    delimiter: '...', // for carousel
    sort: '...',
    expand: mdiMenuDown,
    menu: '...',
    subgroup: '...',
    dropdown: mdiMenuDown,
    radioOn: '...',
    radioOff: '...',
    edit: '...',
    ratingEmpty: '...',
    ratingFull: '...',
    ratingHalf: '...',
    loading: '...',
    first: '...',
    last: '...',
    unfold: '...',
    file: '...',
    /*Additional Icons*/
    arrowLeft: mdiArrowLeft,
    arrowRight: mdiArrowRight,
    send: mdiSend,
    checkboxMarkedOutline: mdiCheckboxMarkedCircleOutline,
    magnify: mdiMagnify,
    appViewDash: mdiViewDashboardOutline,
    accountEdit: mdiAccountEditOutline,
    logout: mdiLogout,
    account: mdiAccount,
    helpCircle: mdiHelpCircle,
    textSearch: mdiTextBoxSearch,
    textSearchOutline: mdiTextBoxSearchOutline,
    exitToApp: mdiExitToApp,
    clipboard: mdiClipboardOutline,
    databaseCog: mdiDatabaseCogOutline,
    databasedown: mdiCheckAll,
    download: mdiDownload,
    export: mdiExport,
    import: mdiImport,
    databaseRemove: mdiDatabaseRemoveOutline,
    databaseAdd: mdiDatabasePlusOutline,
    staron: mdiStar,
    staroff: mdiStarOutline


}


Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: MY_ICONS,
    },
    theme: {
        themes: {
            light: {
                background: '#0C1C24',
                primary: '#283593',
                secondary: '#32424B',
                accent: '#673ab7',
                error: '#f44336',
                warning: '#ff9800',
                info: '#03a9f4',
                success: '#4caf50'
            },
        },
        options: {
            customProperties: true
        },
    },
});
